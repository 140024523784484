#modal{
	white-space: pre-line !important;
	height: 0;
	width: 0;
	padding: 0;
	margin: 0;
	#backdrop{
		position: fixed;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,0.5);
		z-index: 10;
	}
	#content{
		overflow-y: scroll;
		max-height: 95vh;
		position: fixed;box-sizing: border-box;
		top: 50%;
		left: 50%;
		display: inline-block;
		transform: translate(-50%, -50%);
		width: clamp(10vw, 600px, 90vw);
		max-width: 90vw;
		background-color: #fff;
		z-index: 10;
		padding: 40px;
		
		box-shadow: 0 0 10px rgba(0,0,0,0.5);
		border-radius: 4px;
	}
	#buttons{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
		padding-right: 20px;
		flex-wrap: wrap;
		button{
			margin: 3px 30px;
			margin-left: 0px;
			padding: 10px;
			font-size: 1em;
			border-radius: 4px;
			width: 6em;
			border-color: rgb(106, 165, 165);
		}
		#cancel{
			background-color: rgb(255, 162, 162);
		}
		#confirm{
			background-color: rgb(190, 245, 190);
		}
	}
}