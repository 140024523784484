$small: 750px;

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* COMMON */
body {
	margin: 0;
	font-family: "Serif";
	-webkit-font-smfoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #eceffa;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.invisible {
	display: none;
}

.Toastify__toast-body {
	white-space: pre-line;
}

.emoji-avatar {
	display: inline-block;
	font-size: 35px;
	margin: 0;
	padding: 0;
	p {
		margin: 0;
		padding: 0;
	}
}
.user-avatar {
	border-radius: 45%;
	object-fit: cover;
}
.no-btn {
	border: none;
}
.no-btn:focus {
	border: none;
	outline: none;
}
.alert {
	padding: 10px;
	width: 100%;
	box-sizing: content-box;
	border-radius: 5px;
	margin-top: 15px;
	align-self: flex-start;
}

.error-dailog {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
}
.success-dailog {
	color: #174221;
	background-color: #61eb96;
	border-color: #c3e6cb;
}
.warning-dailog {
	color: #ffffff;
	background-color: #ff993a;
	border-color: #a0621b;
}

/* HOME AND WORSHIPPERSLIST COMPONENT */
.home {
	padding: 20px 50px;
	display: flex;
	width: 80%;
	margin: 0 auto;
	@media screen and (max-width: 1070px) {
		box-sizing: border-box;
		width: 100%;
	}
	@media screen and (max-width: 1000px) {
		padding: 20px;
		width: 100%;
	}
	@media screen and (max-width: 700px) {
		padding: 20px 10px;
	}
	@media screen and (max-width: 450px) {
		padding: 20px 3px;
	}
}
.home .posts-list {
	flex-grow: 1;
}

/* LOGIN & SIGNUP COMPONENTS */
.login-signup-header {
	font-weight: 600;
	font-size: 30px;
	color: #ef7917;
}
.login-form {
	background-color: #fff;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 400px;
	margin: 50px auto;
	border-radius: 6px;
}
.field {
	width: 100%;
	box-sizing: border-box;
}
.field input {
	border: 1px solid rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	border-radius: 6px;
	width: 100%;
	height: 40px;
	margin-top: 20px;
	padding: 5px;
	font-size: 15px;
}
.field input:focus {
	outline: none;
}
.field button {
	width: 100%;
	margin-top: 20px;
	background: #ef7917;
	border-radius: 2px;
	color: #fff;
	font-size: 20px;
	padding: 9px;
	border: none;
	outline: none;
}

.round-button {
	cursor: pointer;
	background-color: #dbdafd;
	width: 65px;
	height: 65px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	margin: 0 10px;
	border: #73729d 1px solid;
	box-shadow: 7px 4px 25px 0px rgba(0, 0, 0, 0.25);
	@media screen and (max-width: 325px) {
		margin: 0 5px;
	}
	a {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
