/* SETTINGS and USERPROFILE */
.profile-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}
.profile {
	width: 100%;
	max-width: 600px;
	display: inline-flex;
	box-sizing: content-box;
	flex-direction: column;
	background: #fff;
	padding: 30px;
	margin: 20px 0;

  @media screen and (max-width: 500px) {
		padding: 15px;
  }
}

.profile .img-container {
	display: flex;
	margin-bottom: 40px;
	justify-content: center;
}
.profile .img-container img {
	height: 100px;
}
.profile .btn-grp {
	display: flex;
	flex-direction: column;
}
.profile button {
	border-radius: 6px;
	padding: 10px;
	width: fit-content;
	font-size: 16px;
	border-radius: 3px;
	margin-top: 20px;
}
.profile button:hover {
	cursor: pointer;
}
.profile button:focus {
	outline: none;
}
.profile .edit-btn {
	border: 1px solid #ef7917;
	box-sizing: border-box;
	color: #ef7917;
}
.profile .save-btn {
	background: #ef7917;
	color: #fff;
	border: none;
	padding: 10px 20px;
}
.profile .go-back {
	color: #ef7917;
	margin-top: 7px;
	font-size: 13px;
}
.profile .go-back:hover {
	cursor: pointer;
	text-decoration: underline;
}
.profile .field {
	margin-bottom: 10px;
}
.profile .field .field-label {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #9e9e9e;
	padding: 5px 0;
}
.profile .field input {
	margin-top: 6px;
}

button :active {
	color: black;
	transform: scale(0.98);
	/* Scaling button to 0.98 to its original size */
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	/* Lowering the shadow */
}

button:hover {
	cursor: pointer;
}

button {
	border-color: #e2e2e2;
}
