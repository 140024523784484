#SearchPage {
	padding: 20px 2px;
	.search-container {
		width: 100%;
		box-sizing: border-box;
		margin: 0;
		input{
			font-size: 1.5em;
		}
	}
}
