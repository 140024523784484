
	.search-container {
		position: relative;
		margin-left: 20px;
		width: 380px;
		@media screen and (max-width: 750px) {
			width: 330px;
		}
		@media screen and (max-width: 650px) {
			width: 280px;
		}
	}
	.search-container input {
		position: relative;
		background: #ffffff;
		border: 1px solid #d0d0d0;
		box-sizing: border-box;
		border-radius: 3px;
		height: 40px;
		width: 100%;
		font-size: 16px;
		padding-left: 31px;
		box-sizing: border-box;
		flex-grow: 1;
	}
	.search-container input:focus {
		outline: none;
	}
	.search-icon {
		font-size: 24px;
		position: absolute;
		padding: 0;
		margin: 0;
		top: 7px;
		left: 11px;
		z-index: 2;
	}
	.search-results {
		background: #fff;
		flex-grow: 1;
		border-radius: 0 0 3px 3px;
		position: absolute;
		width: 100%;
		top: 40px;
		border: 1px solid #e0e0e0;
		z-index: 100;
		max-height: 452px;
		overflow: scroll;
	}
	.search-results ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	.search-results ul a {
		text-decoration: none;
		color: black;
	}
	.search-results-row {
		display: flex;
		align-items: center;
		padding: 9px 14px;
		* {
			padding: 2px;
		}
		img {
			font-size: 2em;
			height: 32px;
		}
	}
	.search-results-row:hover {
		background-color: #eceffa;
		cursor: pointer;
		.user-name-search {
			font-size: 1.1em;
		}
	}