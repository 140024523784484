.posts-list {
	width: 1000px;
	max-width: min(100%, 1000px);
	& > .LazyLoad {
		// width: 1000px;
		// max-width: min(100%, 1000px);
		min-height: 20vh;
	}
}

/* Post Item */
.post-wrapper {
	scroll-snap-align: start;
	border: 1px solid #e0e0e0;
	box-sizing: border-box;
	border-radius: 4px;
	margin-top: 60px;
	background: #fff;
}
.post-header {
	padding: 20px;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	@media screen and (max-width: 600px) {
		padding: 10px;
	}
	@media screen and (max-width: 450px) {
		padding-left: 3px;
	}
	.post-header-right {
		display: flex;
		flex-flow: column;
		.post-header-username {
			font-size: 20px;
			a {
				text-decoration: none;

				@media screen and (max-width: 450px) {
					font-size: 0.9em;
				}
			}
			.emoji-avatar {
				font-size: 27.5px;
				margin-right: 3px;
			}
		}
	}
	.post-header-left {
		margin-right: 10px;
	}
}
.post-avatar {
	position: relative;
	display: flex;
	height: 90px;
	width: 90px;
	overflow: hidden;
	& > * {
		position: absolute;
		height: 90px;
		width: 90px;
	}
	@media screen and (max-width: 450px) {
		height: 70px;
		width: 70px;
		& > * {
			height: 70px;
			width: 70px;
		}
	}
}
.post-avatar.emoji-avatar {
	font-size: 40px;
}
.post-avatar-link {
	text-decoration: none;
}
.post-avatar .post-author {
	color: #616161 !important;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}
.post-avatar .post-time {
	/* font-family: Open Sans; */
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #9e9e9e;
}
.post-content {
	/* font-family: Open Sans; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #424242;
	margin-top: 15px;
	margin-bottom: 0;
	.post-text {
		display: block;
		padding: 0 25px;
		font-family: sans-serif;
		margin-bottom: 0;
		max-width: 500px;
		@media screen and (max-width: 600px) {
			padding: 0 15px;
		}
		@media screen and (max-width: 450px) {
			padding: 0 15px;
			font-size: 0.94em;
		}

		.content-toggle-button {
			all: unset;
			cursor: pointer;
			font-size: 0.85em;
			font-weight: 700;
		}
	}
	.post-media {
		position: relative;
		background-color: black;
		max-height: calc(100vh - 200px);
		img,
		video {
			object-fit: contain;
			width: 100%;
			height: min(calc(100vh - 200px), 600px);
			// z-index: 10;
			position: relative;
		}
		.blurhash {
			position: absolute !important;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 0;
		}
		.post-media-nav {
			background: transparent;
			position: absolute;
			height: 600px;
			max-height: 90vh;
			border: none;
			padding: 0;
			font-size: 30px;
			width: 50px;
		}
		.post-media-next {
			right: 0;
		}
		.post-media-prev {
			left: 0;
		}
	}
}
.post-actions {
	display: flex;
	border: 1px solid #e0e0e0;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #616161;
	border-right: none;
	border-left: none;
	box-sizing: border-box;
	height: 40px;
	padding: 5px;
	padding-left: 20px;
	column-gap: 10px;
	& > * {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 10px;
		margin: 0 10px;
		width: 40px;
		cursor: pointer;
	}
}
.post-actions img,
.like img {
	height: 18px;
}
.like {
	flex-flow: row;
	align-items: flex-start;
	justify-content: center;
	width: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: normal;
	line-height: 19px;
	color: #616161;
	column-gap: 10px;
}
.post-comments-icon {
	margin-left: 15px;
	display: flex;
	align-items: flex-start;
}
.post-comment-box {
	padding: 10px 10px;
	display: flex;
}
.post-comment-box input {
	border: 1px solid #e0e0e0;
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	height: 35px;
	margin: 0 auto;
	width: calc(100% - 3em);
	font-size: 15px;
	box-sizing: border-box;
	padding: 5px;
}

.post-comment-box input:focus {
	outline: none;
}
.post-comment-box button {
	display: inline-block;
	width: min-content;
	background: #ef7917;
	color: #fff;
	border: none;
	padding: 10px 20px;
}

.post-comment-box button:active {
	/* transform: scale(0.98); */
	/* Scaling button to 0.98 to its original size */
	box-shadow: 3px 2px 22px 1px rgba(163, 88, 37, 0.54);
	/* Lowering the shadow */
}
.post-comment-box button:focus {
	outline: none;
}
.post-comment-box button:hover {
	cursor: pointer;
}

.post-comments-list {
	padding: 5px 10px;
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;
}
.post-comment-item {
	background: #eceffa;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 14px;
	width: 100%;
	box-sizing: border-box;
}
.post-comment-header {
	display: flex;
	flex-flow: row;
	.like,
	.delete {
		font-size: 12px;
		line-height: 16px;
		color: #616161;
		margin-left: 12px;
		display: flex;
		align-items: center;
		img {
			height: 18px;
		}
	}
	.like {
		margin-left: 20px;
	}
}
.post-comment-header .post-comment-author {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #757575;
}
.post-comment-header .post-comment-time {
	font-size: 12px;
	line-height: 16px;
	color: #9e9e9e;
	margin-left: 8px;
}
.post-comment-content {
	margin-top: 7px;
}
.post-comments-list .comment-like {
	margin-left: 10;
	position: absolute;
	right: 21px;
	top: 13px;
}
.post-comments-list .comment-like img {
	height: 20px;
}
.post-comments-list .comment-like img:hover {
	cursor: pointer;
}
.post-comments-list > button {
	background-color: #ea934d;
	color: white;
	padding: 5px;
	width: 250px;
	box-shadow: none;
	border: rgba(0, 0, 0, 0.2) 1px solid;
	&:active {
		border: none;
		border: #ea934d 1px solid;
	}
}
